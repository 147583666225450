import { VERSION } from './version';


export const environment: any = {
  production: false,
  envName: 'Homolog',
  version: VERSION + '-beta',
  appBaseUrl: 'https://integrador.homolog.pinpag.com.br/web-despachantes',
  xApiKey: 'F78mmlPU4a5KvmIEMfesS4qX5ltxywJC4Mc5eegV',
  origins_api_key: {
    ITURAN: 'Lht36BFNHY44yUc8mjOX88NE0sre3g7K40i8UBk2',
    AUTOSCORE: 'dZGqkIjhWG41lrGMp0IT1a2ubCspj3GZ7yHnwnW2',
    WEBDESPACHANTES: 'F78mmlPU4a5KvmIEMfesS4qX5ltxywJC4Mc5eegV',
    MEGABEM: 'wLBB1uC8wvDKqfhWW9CJ5wg0GEYSGp7vC4pS1Z00',
  },
};
